/*
    Author : Adrien
    Created at : 16/04/2024
    Description : AuthContext (Check if user is logged or not)
*/

import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import authAPI from '../services/authAPI';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});

  const updateUserInfo = useCallback((accessToken = false) => {
    const cookieValue = accessToken ? accessToken : Cookies.get('accessToken');
    if (cookieValue) {
      const decoded = jwtDecode(cookieValue);
      setUserInfo(decoded);
    }
  }, []);

  // Utilisation d'une fonction pour gérer la déconnexion sur erreur 401
  const logoutUser = useCallback(() => {
    setIsLoggedIn(false);
    Cookies.remove('accessToken');
  }, []);

  useEffect(() => {
    const auth = async () => {
      try {
        const res = await authAPI.checkAuth();
        if (res.result) {
          updateUserInfo();
          setIsLoggedIn(true);
        }
      } catch (error) {
        setIsLoggedIn(false);
      } finally {
        setLoading(false);
      }
    };

    const cookieValue = Cookies.get('accessToken');
    if (!cookieValue || typeof cookieValue === 'undefined') {
      auth();
    } else {
      updateUserInfo();
      setIsLoggedIn(true);
      setLoading(false);
    }

    // Configure axios interceptor inside useEffect
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          logoutUser();
        }
        return Promise.reject(error);
      }
    );

    // Clean up the interceptor on unmount
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [updateUserInfo, logoutUser]);

  const login = useCallback(
    (token = false) => {
      updateUserInfo(token);
      setIsLoggedIn(true);
    },
    [updateUserInfo]
  );

  const logout = useCallback(async () => {
    try {
      const res = await authAPI.signout();
      if (res.data.result) {
        setIsLoggedIn(false);
      } else {
        // Utiliser un système de notification plus approprié
        console.error('Error during sign out!');
      }
    } catch (error) {
      console.error('Error catch during sign out!');
    }
  }, []);

  if (loading) {
    return (
      <div className="loading_section">
        <ClipLoader color={'#E8768A'} loading={loading} size={50} />
      </div>
    );
  } else {
    return (
      <AuthContext.Provider value={{ isLoggedIn, userInfo, login, logout }}>
        {children}
      </AuthContext.Provider>
    );
  }
};

export const useAuth = () => useContext(AuthContext);
