import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const ImageWithLoader = ({ src, alt, className, style }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  useEffect(() => {
    setTimeout(function () {
      if (!isLoaded) {
        setIsLoaded(true);
      }
    }, 1500);
  }, [isLoaded]);

  return (
    <div className={className}>
      {!isLoaded && (
        <div className="loader d-flex justify-content-center">
          <ClipLoader color={'#E8768A'} loading={true} size={25} />
        </div>
      )}
      <img
        src={src}
        alt={alt}
        onLoad={handleImageLoad}
        style={{ ...(style ?? ''), display: isLoaded ? 'block' : 'none' }}
      />
    </div>
  );
};

export default ImageWithLoader;
