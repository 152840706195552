import axios from 'axios';

const authAPI = {
  async signin(credentials) {
    return axios
      .post(process.env.REACT_APP_API_URL + 'users/signin', credentials, {
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  },

  async forgotPassword(credentials) {
    return axios
      .post(process.env.REACT_APP_API_URL + 'users/forgot_password', credentials, {
        withCredentials: true,
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  },

  async resetPassword(credentials) {
    try {
      return axios
        .post(process.env.REACT_APP_API_URL + 'users/reset_password', credentials, {
          withCredentials: true,
        })
        .then(function (response) {
          return response;
        })
        .catch(function (error) {
          return error;
        });
    } catch (error) {
      return error;
    }
  },

  async signout() {
    return axios
      .post(
        process.env.REACT_APP_API_URL + 'users/signout',
        {},
        {
          withCredentials: true,
        }
      )
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return "Oups ! Quelque chose s'est mal passé22";
      });
  },

  // Test if authenticated
  async checkAuth() {
    return axios
      .get(process.env.REACT_APP_API_URL + 'users/checkAuth', {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return "Oups ! Quelque chose s'est mal passé33";
      });
  },

  async getTokenRp() {
    return axios
      .get(process.env.REACT_APP_API_URL + 'users/getTokenRp', {
        withCredentials: true,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return "Oups ! Quelque chose s'est mal passé  getTokenRp44";
      });
  },
};

export default authAPI;
