import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@flaticon/flaticon-uicons/css/bold/all.css';
import '@flaticon/flaticon-uicons/css/regular/all.css';
import '@flaticon/flaticon-uicons/css/solid/all.css';
import '@flaticon/flaticon-uicons/css/thin/rounded.css';
// import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './i18n';

// Bootstrap / Primereact
import 'primereact/resources/themes/lara-light-cyan/theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
